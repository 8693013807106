import { TextField } from "@material-ui/core";
import {
  DatePicker as MuiDatePicker,
  LocalizationProvider
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import "moment/locale/it"; // Importa la lingua italiana per moment

import I18n from "../../models/i18n";

class DatePicker extends PureComponent {
  static propTypes = {
    onDateRangeChanged: PropTypes.func.isRequired,
    lang: PropTypes.object.isRequired,
    showCharts: PropTypes.bool
  };

  state = {
    value: null,
    states: null,
    startDate: this.props.startDate,
    endDate: this.props.endDate
  };

  render() {
    const { startDate, endDate, showCharts } = this.props;

    return (
      <div>
        <LocalizationProvider adapterLocale="it" dateAdapter={AdapterMoment}>
          <div className="row">
            <div className="col-6">
              <MuiDatePicker
                disableFuture
                label={I18n.translate("calendar", "start")}
                openTo="day"
                locale={this.props.lang.lang}
                views={["year", "month", "day"]}
                value={this.state.startDate}
                onChange={this.handleStartDateSelect}
                maxDate={this.getMaximumDate()}
                renderInput={params => (
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: I18n.translate(
                        "calendar",
                        "date_placeholder"
                      )
                    }}
                  />
                )}
              />
            </div>
            <div className="col-6">
              <MuiDatePicker
                disableFuture
                label={I18n.translate("calendar", "end")}
                openTo="day"
                locale={this.props.lang.lang}
                views={["year", "month", "day"]}
                value={this.state.endDate}
                onChange={this.handleEndDateSelect}
                maxDate={this.getMaximumDate()}
                renderInput={params => (
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: I18n.translate(
                        "calendar",
                        "date_placeholder"
                      )
                    }}
                  />
                )}
              />
            </div>
          </div>
        </LocalizationProvider>
      </div>
    );
  }

  /**
   *
   * @param {*} date
   */
  handleStartDateSelect = date => {
    this.setState(
      {
        startDate: date
      },
      () => {
        this.updateDateRange();
      }
    );
  };

  /**
   *
   * @param {*} date
   */
  handleEndDateSelect = date => {
    console.log(date);
    this.setState(
      {
        endDate: date
      },
      () => {
        this.updateDateRange();
      }
    );
  };

  /**
   *
   */
  updateDateRange() {
    const rangeStart = this.state.startDate
      ? moment(this.state.startDate, "DD/MM/YYYY").format("YYYYMMDD")
      : null;
    const rangeEnd = this.state.endDate
      ? moment(this.state.endDate, "DD/MM/YYYY").format("YYYYMMDD")
      : null;

    // serve una stringa da mettere nell'url
    if (rangeStart && rangeEnd)
      this.props.onDateRangeChanged(rangeStart + "|" + rangeEnd);
  }

  getMaximumDate = () => {
    return moment(new Date()).toDate();
  };
}

export default DatePicker;
