import React, { Component } from "react";
//import { postData } from '../../lib/utils/httputils';

const logErrorToMyService = function(error, info) {
  //postData('/api/v1/error', { error, info }).finally();
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h1>Something went wronsg :-(</h1>
          <p>
            We've beeing notified and we're working on it.. Please try again
            later..
          </p>
          <a href="/">Home</a>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
