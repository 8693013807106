import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import I18n from "../../models/i18n";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

class Timeframe extends PureComponent {
  static propTypes = {
    timeframe: PropTypes.string.isRequired,
    pushTimeframe: PropTypes.func.isRequired
  };

  render() {
    const MenuProps = {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left"
      },
      getContentAnchorEl: null
    };

    const { timeframe, pushTimeframe } = this.props;
    return (
      <FormControl variant="outlined" size="small" fullWidth>
        <InputLabel id="timeframe-label">
          {I18n.translate("data", "choose")}
        </InputLabel>
        <Select
          labelId="timeframe-label"
          label={I18n.translate("data", "choose")}
          value={timeframe}
          onChange={pushTimeframe}
          MenuProps={MenuProps}
        >
          <MenuItem value="last_day">
            {I18n.translate("data", "giorno")}
          </MenuItem>
          <MenuItem value="last_week">
            {I18n.translate("data", "settimana")}
          </MenuItem>
          <MenuItem value="last_month">
            {I18n.translate("data", "mese")}
          </MenuItem>
          <MenuItem value="last_year">
            {I18n.translate("data", "anno")}
          </MenuItem>
          <MenuItem value="custom">{I18n.translate("data", "custom")}</MenuItem>
        </Select>
      </FormControl>
    );
  }
}

export default Timeframe;
