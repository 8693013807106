import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "react-vis/dist/style.css";
import TempChart from "../charts/TempChart";
import HumChart from "../charts/HumChart";
import PmChart from "../charts/PmChart";
import PressChart from "../charts/PressChart";
import { retrieveData, setData } from "../../models/data";
import { withRouter } from "react-router-dom";
import { setCurrentStation } from "../../models/station";
import qs from "qs";
import DatePicker from "../CommonComponents/DatePicker";
import Timeframe from "../CommonComponents/Timeframe";
import Pm25Chart from "../charts/Pm25Chart";
import SerieChart from "../charts/SerieChart";
import { getStorageKey, setStorageKey } from "../../lib/utils/storageUtils";
import I18n from "../../models/i18n";
import { getMSPColor } from "../../lib/utils/mspUtils";
import MSPChart from "../charts/MSPChart";
import Loading from "../CommonComponents/Loading";
import moment from "moment";

const getData = function(data, type, interval) {
  if (!data || !Array.isArray(data)) return [];

  const filtered = data
    .filter(item => {
      const val = item[type];
      if (val === undefined || val === null) {
        return false;
      }
      return !isNaN(val);
    })
    .map(item => {
      const { ts } = item;
      const val = Number(item[type]);
      return {
        x: ts,
        y: val
      };
    });
  if (interval) {
    return splitDataByInterval(filtered, interval);
  }
  return filtered;
};

const splitDataByInterval = function(data, interval) {
  const series = [];
  let prevTs = 0;
  let idx = -1;
  data.forEach(function(point) {
    if (point.x - interval > prevTs) {
      series.push([]);
      idx++;
    }
    series[idx].push(point);
    prevTs = point.x;
  });
  return series;
};

const DAY_INTERVAL = 86400000;
const TWO_HOURS_INTERVAL = 7200000;

class Data extends PureComponent {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    currentStation: PropTypes.object
  };

  state = {
    width: undefined,
    resizing: false,
    // layout: 'grid',
    dataTemp: undefined,
    dataHum: undefined,
    dataPres: undefined,
    dataPm1: undefined,
    dataPm2_5: undefined,
    dataPm10: undefined,
    dataHumidex: undefined,
    dataTempMax: undefined,
    dataTempMin: undefined,
    dataHumMax: undefined,
    dataHumMin: undefined,
    dataNOx: undefined,
    dataCO: undefined,
    dataVOC: undefined,
    dataNH3: undefined,
    dataO3: undefined,
    ticksX: undefined,
    custom: false,
    latest: undefined
  };

  timeoutResize = null;

  refCol = React.createRef();

  constructor(props) {
    super(props);
    this.isEmbed = this.props.location.pathname.indexOf("/embed") > 0;
  }

  render() {
    const { station, data } = this.props;
    if (!station.currentStation) {
      return <Loading />;
    }
    //if (!data.loaded) {
    //return <div>Loading...</div>;
    //}
    if (data.error) {
      return <div>Ops, unable to load data</div>;
    }

    const layout = getStorageKey("layout", "grid");
    let {
      dataTemp,
      dataTempMax,
      dataTempMin,
      dataHum,
      dataHumMax,
      dataHumMin,
      dataPres,
      dataPm1,
      dataPm2_5,
      dataPm10,
      dataNOx,
      dataVOC,
      dataCO,
      dataO3,
      dataNH3,
      dataMSP,
      //dataHumidex,
      ticksX
    } = this.state;
    const { latest } = this.state;
    if (dataTemp === undefined) {
      return <Loading />;
    }
    if (!dataPm1) {
      return <Loading />;
    }
    const timeframe = this.getTimeframe();
    const getDateTime = function(value) {
      if (!value) return "";
      switch (timeframe) {
        case "last_day":
          return value.toLocaleDateString() + " " + value.toLocaleTimeString();

        default:
          return value.toLocaleDateString();
      }
    };
    let showCharts = false;
    if (data.data) {
      showCharts =
        data.data.length > 0 && this.state.width && !this.state.resizing;
    }
    let lastData;
    let lastDataDate;
    if (latest && latest.record.length > 0) {
      lastData = latest.record[0];
      lastDataDate = new Date(lastData.recordedAt);
    }
    const { currentStation } = station;
    const csvDownloadSearch = this.props.location.search
      ? this.props.location.search + "&detail=1"
      : "?detail=1";
    return (
      <div className="pm-page">
        <div className="top-page bg-grey">
          <div className="container py-5">
            <div className="row">
              <div className="col-12 col-md-3">
                <h5 style={{ textTransform: "capitalize" }}>
                  {currentStation.name}
                </h5>
                <div className="mb-3">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://www.openstreetmap.org/?mlat=${currentStation.latitude}&mlon=${currentStation.longitude}#map=14/${currentStation.latitude}/${currentStation.longitude}`}
                  >
                    {currentStation.longitude},{currentStation.latitude}
                  </a>
                  <br />
                  {currentStation.indoor === 1 && (
                    <>
                      <em>INDOOR</em>
                      <br />
                    </>
                  )}
                </div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/api/v1/stations/${currentStation.id}/data.csv${csvDownloadSearch}`}
                  className="btn btn-secondary"
                >
                  EXPORT CSV
                </a>
              </div>

              <div className="col-12 col-md-6">
                <Timeframe
                  timeframe={timeframe}
                  pushTimeframe={this.pushTimeframe}
                />
                <div className="mt-3">
                  {timeframe === "custom" && (
                    <DatePicker
                      // range={this.getRange()}
                      startDate={this.getStartDateFilter()}
                      endDate={this.getEndDateFilter()}
                      lang={this.props.lang}
                      onDateRangeChanged={this.onDateRangeChanged}
                      showCharts={showCharts}
                    />
                  )}
                </div>
              </div>
              <div className="col-12 col-md-3 d-none d-md-block">
                <div className="icone-layout">
                  <button
                    className={
                      "layout-button" +
                      (layout === "grid" ? " layout-active" : "")
                    }
                    onClick={e => this.changeLayout(e, "grid")}
                  >
                    <img
                      className="img-fluid"
                      src="/assets/icon-grid.png"
                      alt="icona layout griglia"
                    />
                  </button>
                  <button
                    className={
                      "layout-button" +
                      (layout === "list" ? " layout-active" : "")
                    }
                    onClick={e => this.changeLayout(e, "list")}
                  >
                    <img
                      className="img-fluid"
                      src="/assets/icon-list.png"
                      alt="icona layout lista"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {latest && lastDataDate && (
          <div
            className={
              layout === "grid" ? "container-fluid mt-4" : "container mt-4"
            }
          >
            <div className="text-center graphs-group row">
              <div
                className={
                  "col-12  mb-4" + (layout === "grid" ? " col-md-6" : "")
                }
              >
                <div className="shadow-lg card">
                  <div className="card-header">
                    <h3 className="py-2 chart-title">
                      {I18n.translate("data", "last_data")}{" "}
                      {lastDataDate.toLocaleDateString()}{" "}
                      {lastDataDate.toLocaleTimeString()}
                    </h3>
                  </div>
                  <div className="card-body row">
                    <div className="pt-2 mb-2 col-6 col-md-3 col-lg-2 border-top">
                      {I18n.translate("data", "temperatura")}
                    </div>
                    <div className="pt-2 mb-2 col-6 col-md-3 col-lg-2 border-top">
                      <strong>{lastData.temp} °C</strong>
                    </div>
                    <div className="pt-2 mb-2 col-6 col-md-3 col-lg-2 border-top">
                      {I18n.translate("data", "humidity")}
                    </div>
                    <div className="pt-2 mb-2 col-6 col-md-3 col-lg-2 border-top">
                      <strong>{lastData.hum} %</strong>
                    </div>
                    <div className="pt-2 mb-2 col-6 col-md-3 col-lg-2 border-top">
                      {I18n.translate("data", "pressure")}
                    </div>
                    <div className="pt-2 mb-2 col-6 col-md-3 col-lg-2 border-top">
                      <strong>{lastData.pres} hPa</strong>
                    </div>
                    <div className="pt-2 mb-2 col-6 col-md-3 col-lg-2 border-top">
                      PM 1
                    </div>
                    <div className="pt-2 mb-2 col-6 col-md-3 col-lg-2 border-top">
                      <strong>{lastData.PM1 || "-"} μg/m³</strong>
                    </div>
                    <div className="pt-2 mb-2 col-6 col-md-3 col-lg-2 border-top">
                      PM 2.5
                    </div>
                    <div className="pt-2 mb-2 col-6 col-md-3 col-lg-2 border-top">
                      <strong>{lastData.PM2_5 || "-"} μg/m³</strong>
                    </div>
                    <div className="pt-2 mb-2 col-6 col-md-3 col-lg-2 border-top">
                      PM 10
                    </div>
                    <div className="pt-2 mb-2 col-6 col-md-3 col-lg-2 border-top">
                      <strong>{lastData.PM10 || "-"} μg/m³</strong>
                    </div>
                    {currentStation.station_type === "MilanoSmartPark" && (
                      <>
                        <div className="pt-2 mb-2 col-6 col-md-3 col-lg-2 border-top">
                          NOx
                        </div>
                        <div className="pt-2 mb-2 col-6 col-md-3 col-lg-2 border-top">
                          <strong>{lastData.nox || "-"} μg/m³</strong>
                        </div>
                        <div className="pt-2 mb-2 col-6 col-md-3 col-lg-2 border-top">
                          CO
                        </div>
                        <div className="pt-2 mb-2 col-6 col-md-3 col-lg-2 border-top">
                          <strong>{lastData.co || "-"} μg/m³</strong>
                        </div>
                        <div className="pt-2 mb-2 col-6 col-md-3 col-lg-2 border-top">
                          VOC
                        </div>
                        <div className="pt-2 mb-2 col-6 col-md-3 col-lg-2 border-top">
                          <strong>{lastData.voc || "-"} kOhm</strong>
                        </div>
                        <div className="pt-2 mb-2 col-6 col-md-3 col-lg-2 border-top">
                          NH3
                        </div>
                        <div className="pt-2 mb-2 col-6 col-md-3 col-lg-2 border-top">
                          <strong>{lastData.nh3 || "-"} μg/m³</strong>
                        </div>
                        <div className="pt-2 mb-2 col-6 col-md-3 col-lg-2 border-top">
                          O3
                        </div>
                        <div className="pt-2 mb-2 col-6 col-md-3 col-lg-2 border-top">
                          <strong>{lastData.o3 || "-"} μg/m³</strong>
                        </div>
                      </>
                    )}
                    {!!lastData.msp && (
                      <>
                        <div className="pt-2 mb-2 col-6 col-md-3 col-lg-2 border-top">
                          MSP#
                        </div>
                        <div className="pt-2 mb-2 col-6 col-md-3 col-lg-2 border-top">
                          <strong className="mr-2">{lastData.msp}</strong>
                          <svg width="12" height="12">
                            <rect
                              width="12"
                              height="12"
                              style={{
                                fill: getMSPColor(lastData.msp),
                                strokeWidth: 3,
                                stroke: getMSPColor(lastData.msp)
                              }}
                            />
                          </svg>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={
                  "col-12  mb-4" + (layout === "grid" ? " col-md-6" : "")
                }
              >
                <div className="shadow-lg card">
                  <div className="card-header">
                    <h3 className="py-2 chart-title">
                      {I18n.translate("data", "day_over_in_year_title")}
                      <br />
                      {I18n.translate("data", "day_over_in_year_subtitle")}
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="pt-2 mb-2 col-6 col-md-3 col-lg-2 border-top">
                        PM 2.5
                      </div>
                      <div className="pt-2 mb-2 col-6 col-md-3 col-lg-2 border-top">
                        <strong>
                          {latest.daysover.PM2_5 || "-"}{" "}
                          {I18n.translate("data", "days")}
                        </strong>
                      </div>
                      <div className="pt-2 mb-2 col-6 col-md-3 col-lg-2 border-top">
                        PM 10
                      </div>
                      <div className="pt-2 mb-2 col-6 col-md-3 col-lg-2 border-top">
                        <strong>
                          {latest.daysover.PM10 || "-"}{" "}
                          {I18n.translate("data", "days")}
                        </strong>
                      </div>
                    </div>
                    <div className="mt-2">
                      <em>{I18n.translate("data", "PM10_25_limit_text")}</em>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!showCharts && (
          <div className="container py-5 text-center">
            <div className="alert alert-warning">
              {I18n.translate("data", "no_results")}
            </div>
          </div>
        )}
        {data.data === null && <div>Did you choose a period?</div>}
        <div
          className={layout === "grid" ? "container-fluid" : "container mt-4"}
        >
          <div className="text-center graphs-group row">
            <div
              className={
                "col-12  mb-4" + (layout === "grid" ? " col-md-6" : "")
              }
            >
              {showCharts &&
                !currentStation.options.sensors.tolerance.PM1 &&
                dataPm2_5 &&
                dataPm2_5.length > 0 && (
                  <Pm25Chart
                    dataPm2_5={dataPm2_5}
                    getDateTime={getDateTime}
                    width={this.state.width}
                    ticksX={ticksX}
                    sensors={
                      currentStation.options && currentStation.options.sensors
                        ? currentStation.options.sensors
                        : {}
                    }
                  />
                )}
              {showCharts &&
                currentStation.options.sensors.tolerance.PM1 &&
                dataPm2_5 &&
                dataPm2_5.length > 0 && (
                  <PmChart
                    dataPm1={dataPm1}
                    dataPm2_5={dataPm2_5}
                    dataPm10={dataPm10}
                    getDateTime={getDateTime}
                    width={this.state.width}
                    ticksX={ticksX}
                    sensors={
                      currentStation.options && currentStation.options.sensors
                        ? currentStation.options.sensors
                        : {}
                    }
                  />
                )}
            </div>
            {showCharts && dataMSP && dataMSP.length > 1 && (
              <div
                className={
                  "col-12  mb-4" + (layout === "grid" ? " col-md-6" : "")
                }
              >
                <MSPChart
                  title="MSP#"
                  code="msp"
                  dataSerie={dataMSP}
                  getDateTime={getDateTime}
                  width={this.state.width}
                  labelY="index"
                  sensors={
                    currentStation.options && currentStation.options.sensors
                      ? currentStation.options.sensors
                      : {}
                  }
                  ticksX={ticksX}
                />
              </div>
            )}
            {showCharts && dataHum && (
              <div
                className={
                  "col-12  mb-4" + (layout === "grid" ? " col-md-6" : "")
                }
              >
                <HumChart
                  dataHum={dataHum}
                  dataHumMax={timeframe === "last_day" ? null : dataHumMax}
                  dataHumMin={timeframe === "last_day" ? null : dataHumMin}
                  getDateTime={getDateTime}
                  width={this.state.width}
                  ticksX={ticksX}
                />
              </div>
            )}
            <div
              className={
                "col-12  mb-4" + (layout === "grid" ? " col-md-6" : "")
              }
              ref={this.refCol}
            >
              {showCharts && dataTemp && (
                <TempChart
                  dataTemp={dataTemp}
                  dataTempMax={timeframe === "last_day" ? null : dataTempMax}
                  dataTempMin={timeframe === "last_day" ? null : dataTempMin}
                  //dataHumidex={dataHumidex}
                  getDateTime={getDateTime}
                  width={this.state.width}
                  ticksX={ticksX}
                />
              )}
            </div>
            <div
              className={
                "col-12  mb-4" + (layout === "grid" ? " col-md-6" : "")
              }
            >
              {showCharts && dataPres && (
                <PressChart
                  dataPres={dataPres}
                  getDateTime={getDateTime}
                  width={this.state.width}
                  ticksX={ticksX}
                />
              )}
            </div>
            {currentStation.station_type === "MilanoSmartPark" && (
              <>
                <div
                  className={
                    "col-12  mb-4" + (layout === "grid" ? " col-md-6" : "")
                  }
                >
                  {showCharts && dataNOx && dataNOx.length > 0 && (
                    <SerieChart
                      title="NOx"
                      code="nox"
                      dataSerie={dataNOx}
                      getDateTime={getDateTime}
                      width={this.state.width}
                      labelY="µg/m3"
                      sensors={
                        currentStation.options && currentStation.options.sensors
                          ? currentStation.options.sensors
                          : {}
                      }
                      ticksX={ticksX}
                    />
                  )}
                </div>
                <div
                  className={
                    "col-12  mb-4" + (layout === "grid" ? " col-md-6" : "")
                  }
                >
                  {showCharts && dataCO && dataCO.length > 0 && (
                    <SerieChart
                      title="CO"
                      code="co"
                      dataSerie={dataCO}
                      getDateTime={getDateTime}
                      width={this.state.width}
                      labelY="µg/m3"
                      sensors={
                        currentStation.options && currentStation.options.sensors
                          ? currentStation.options.sensors
                          : {}
                      }
                      ticksX={ticksX}
                    />
                  )}
                </div>
                <div
                  className={
                    "col-12  mb-4" + (layout === "grid" ? " col-md-6" : "")
                  }
                >
                  {showCharts && dataVOC && dataVOC.length > 0 && (
                    <SerieChart
                      title="VOC"
                      code="voc"
                      dataSerie={dataVOC}
                      getDateTime={getDateTime}
                      labelY="kOhm"
                      sensors={
                        currentStation.options && currentStation.options.sensors
                          ? currentStation.options.sensors
                          : {}
                      }
                      width={this.state.width}
                      ticksX={ticksX}
                    />
                  )}
                </div>
                <div
                  className={
                    "col-12  mb-4" + (layout === "grid" ? " col-md-6" : "")
                  }
                >
                  {showCharts && dataNH3 && dataNH3.length > 0 && (
                    <SerieChart
                      title="NH3"
                      code="nh3"
                      dataSerie={dataNH3}
                      getDateTime={getDateTime}
                      width={this.state.width}
                      labelY="µg/m3"
                      sensors={
                        currentStation.options && currentStation.options.sensors
                          ? currentStation.options.sensors
                          : {}
                      }
                      ticksX={ticksX}
                    />
                  )}
                </div>
                <div
                  className={
                    "col-12  mb-4" + (layout === "grid" ? " col-md-6" : "")
                  }
                >
                  {showCharts && dataO3 && dataO3.length > 0 && (
                    <SerieChart
                      title="O3"
                      code="o3"
                      dataSerie={dataO3}
                      getDateTime={getDateTime}
                      width={this.state.width}
                      labelY="µg/m3"
                      sensors={
                        currentStation.options && currentStation.options.sensors
                          ? currentStation.options.sensors
                          : {}
                      }
                      ticksX={ticksX}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    if (!this.props.station.currentStation) {
      this.fetchStation().catch(err => {
        console.error("failed to load station", err.message);
      });
    } else {
      this.fetchDataFromStation().catch(err => console.error(err));
      this.fetchLatestData().catch(err => console.error(err));
    }
    window.addEventListener("resize", this.checkDimensions);
    this.checkDimensions(undefined, true);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !prevProps.station.currentStation &&
      this.props.station.currentStation
    ) {
      this.fetchLatestData().catch(err => console.error(err));
    }
    if (
      (!prevProps.station.currentStation &&
        this.props.station.currentStation) ||
      prevProps.location.search !== this.props.location.search
    ) {
      if (this.getTimeframe() !== "custom") {
        this.fetchDataFromStation().finally();
      } else if (this.getRange() !== undefined) {
        this.fetchDataFromStation().finally();
      }
    }
    if (this.state.width === undefined) {
      this.checkDimensions(undefined, true);
    }
    if (
      prevProps.data.loading &&
      !this.props.data.loading &&
      !this.props.data.error &&
      this.props.data.data
    ) {
      this.parseData();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkDimensions);
  }

  fetchStation = async () => {
    try {
      const stationId = this.props.match.params.station;
      const res = await fetch("/api/v1/stations/" + stationId);
      const station = await res.json();
      this.props.dispatch(setCurrentStation(station));
    } catch (err) {
      console.error("failed fetchStation", err.message);
    }
  };

  fetchDataFromStation = async () => {
    const { station, dispatch } = this.props;
    dispatch(retrieveData());
    this.fetchData(station.currentStation.id)
      .then(data => {
        dispatch(setData(data));
      })
      .catch(err => {
        console.error("failed fetchDataFromStation", err.message);
      });
  };

  fetchData = async id => {
    const period = this.getTimeframe();
    let url = "/api/v1/stations/" + id + "/data?period=" + period;
    if (period === "custom") {
      url += "&range=" + this.getRange();
    }
    const res = await fetch(url);
    return res.json();
  };

  fetchLatestData = async () => {
    const { station } = this.props;
    const res = await fetch(
      `/api/v1/stations/${station.currentStation.id}/latest`
    );
    const data = await res.json();
    this.setState({ latest: data });
  };

  getTimeframe = () => {
    const queries = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    return queries.p || "last_day";
  };

  getRange = () => {
    const queries = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    return queries.range;
  };

  getStartDateFilter = queries => {
    if (!queries) {
      queries = qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true
      });
    }
    if (!queries.range) return null;

    const date = queries.range.split("|")[0];
    return moment(date, "YYYYMMDD");
  };

  getEndDateFilter = queries => {
    if (!queries) {
      queries = qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true
      });
    }
    if (!queries.range) return null;

    const date = queries.range.split("|")[1];
    return moment(date, "YYYYMMDD");
  };

  pushTimeframe = e => {
    const { value } = e.target;
    // e.target.blur();
    this.props.history.push(
      "/stations/" +
        (this.isEmbed ? "embed/" : "") +
        this.props.station.currentStation.code +
        "?p=" +
        value
    );
  };

  onDateRangeChanged = async range => {
    if (range) {
      this.props.history.push(
        "/stations/" +
          (this.isEmbed ? "embed/" : "") +
          this.props.station.currentStation.code +
          "?p=custom&range=" +
          range
      );
    }
  };

  checkDimensions = (e, force = false) => {
    if (this.refCol && this.refCol.current) {
      if (this.state.width !== this.refCol.current.offsetWidth) {
        if (force) {
          this.setState({ width: this.refCol.current.offsetWidth });
          return;
        }
        if (!this.state.resizing) {
          this.setState({ resizing: true });
        }
        if (this.timeoutResize) {
          clearTimeout(this.timeoutResize);
        }
        this.timeoutResize = setTimeout(() => {
          this.setState({
            width: this.refCol.current.offsetWidth,
            resizing: false
          });
        }, 300);
      }
    }
  };

  changeLayout = (e, layout) => {
    e.preventDefault();
    const storeLayout = getStorageKey("layout", "");
    if (storeLayout === layout) {
      return;
    }
    if (storeLayout === "grid") {
      setStorageKey("layout", "list");
    } else {
      setStorageKey("layout", "grid");
    }
    this.forceUpdate(() => {
      this.checkDimensions(undefined, true);
    });
  };

  parseData = () => {
    const timeframe = this.getTimeframe();
    let single_day = timeframe === "last_day";
    const range = this.getRange();
    if (range) {
      const dates = range.split("|");
      single_day = single_day || dates[0] === dates[1];
    }
    const {
      station,
      data: { data }
    } = this.props;

    if (data && Array.isArray(data)) {
      data.map(item => {
        return (item.ts = new Date(item.recordedAt));
      });
    }

    if (single_day) {
      this.parseDataDay(data, station);
    } else {
      this.parseDataOther(data, station, timeframe);
    }
  };

  parseDataOther = (data, station, timeframe) => {
    let dataTemp;
    let dataTempMax;
    let dataTempMin;
    let dataHum;
    let dataHumMax;
    let dataHumMin;
    let dataPres;
    let dataPresMax;
    let dataPresMin;
    let dataNOx;
    let dataCO;
    let dataVOC;
    let dataO3;
    let dataNH3;
    let dataMSP;
    let dataPm1;
    let dataPm2_5;
    let dataPm10;
    //let dataHumidex;
    let ticksX;

    let interval = ["last_month", "last_week"].includes(timeframe)
      ? DAY_INTERVAL
      : DAY_INTERVAL * 32;
    dataTemp = getData(data, "avgTemp", interval);
    dataTempMin = getData(data, "minTemp", interval);
    dataTempMax = getData(data, "maxTemp", interval);
    dataHum = getData(data, "avgHum", interval);
    dataHumMax = getData(data, "maxHum", interval);
    dataHumMin = getData(data, "minHum", interval);
    dataPres = getData(data, "avgPres", interval);
    dataPresMax = getData(data, "maxPres", interval);
    dataPresMin = getData(data, "minPres", interval);
    dataPm1 = getData(data, "avgPM1", interval);
    dataPm2_5 = getData(data, "avgPM2_5", interval);
    dataPm10 = getData(data, "avgPM10", interval);

    if (station.currentStation.station_type === "MilanoSmartPark") {
      dataNOx = getData(data, "avgNox", interval);
      dataCO = getData(data, "avgCO", interval);
      dataVOC = getData(data, "avgVoc", interval);
      dataO3 = getData(data, "avgO3", interval);
      dataNH3 = getData(data, "avgNh3", interval);
      dataMSP = getData(data, "avgMsp");
    }

    const recordsNum = data.length;
    const halfTicks = recordsNum / 2;
    if (this.state.width > 700) {
      ticksX = recordsNum > 31 ? halfTicks : recordsNum;
    } else {
      if (recordsNum < 13) {
        ticksX = recordsNum;
      } else if (recordsNum > 50) {
        ticksX = recordsNum / 4;
      } else if (recordsNum > 31) {
        ticksX = recordsNum / 3;
      } else {
        ticksX = halfTicks;
      }
    }
    this.setState({
      dataTemp,
      dataHum,
      dataHumMax,
      dataHumMin,
      dataPres,
      dataPresMax,
      dataPresMin,
      dataPm1,
      dataPm2_5,
      dataPm10,
      //dataHumidex,
      ticksX,
      dataTempMax,
      dataTempMin,
      dataNOx,
      dataCO,
      dataVOC,
      dataO3,
      dataNH3,
      dataMSP
    });
  };

  parseDataDay = (data, station) => {
    let dataTemp;
    let dataHum;
    let dataPres;
    let dataPm1;
    let dataPm2_5;
    let dataPm10;
    let dataNOx;
    let dataCO;
    let dataO3;
    let dataNH3;
    let dataVOC;
    let dataMSP;
    //let dataHumidex;
    let ticksX;

    dataTemp = getData(data, "temp", TWO_HOURS_INTERVAL);
    dataHum = getData(data, "hum", TWO_HOURS_INTERVAL);
    dataPres = getData(data, "pres", TWO_HOURS_INTERVAL);
    dataPm1 = getData(data, "PM1", TWO_HOURS_INTERVAL);
    dataPm2_5 = getData(data, "PM2_5", TWO_HOURS_INTERVAL);
    dataPm10 = getData(data, "PM10", TWO_HOURS_INTERVAL);
    if (station.currentStation.station_type === "MilanoSmartPark") {
      dataNOx = getData(data, "nox", TWO_HOURS_INTERVAL);
      dataCO = getData(data, "co", TWO_HOURS_INTERVAL);
      dataVOC = getData(data, "voc", TWO_HOURS_INTERVAL);
      dataO3 = getData(data, "o3", TWO_HOURS_INTERVAL);
      dataNH3 = getData(data, "nh3", TWO_HOURS_INTERVAL);
      dataMSP = getData(data, "msp");
    }
    /* dataHumidex = data.map((item, i) => {
      const { ts, humidex } = item;
      if (isNaN(humidex)) {
        console.error('avgTemp %s ts %s', humidex, ts);
      }
      return {
        x: ts,
        y: humidex
      };
    });*/
    const recordsNum = data.length;
    ticksX =
      recordsNum < 13
        ? recordsNum
        : this.state.width > 700
        ? recordsNum > 31
          ? recordsNum / 2
          : recordsNum
        : recordsNum / 2;
    this.setState({
      dataTemp,
      dataHum,
      dataPres,
      dataPm1,
      dataPm2_5,
      dataPm10,
      dataNOx,
      dataCO,
      dataVOC,
      dataO3,
      dataNH3,
      dataMSP,
      //dataHumidex,
      ticksX
    });
  };
}

function mapStateToProps(state) {
  const { lang, data, station } = state;

  return {
    data,
    station,
    lang
  };
}

export default withRouter(connect(mapStateToProps)(Data));
